
import {computed, defineComponent, ref} from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import { generalStore } from '@/store';
import router from '@/router';
import {EmployeeFilter, EmployeeSchedule} from "@/models/Employee";
import {api} from "@/services/Api";
import { IdName } from '@/models/Interfaces';
import {DayOfWeek} from "@/models/Enums";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import TimeInput from "@/components/TimeInput.vue";
import moment from "moment/moment";

export default defineComponent({
    name: 'EmployeesFilter',
    components: { CheckBoxInput, TimeInput },
    async setup() {
        const openAddTagsModal = ref(false);
        const weekDayNames = DayOfWeek;
        const filter = computed<EmployeeFilter>(() => generalStore.state.employeesFilter);
        const filterBeforeSave = JSON.parse(JSON.stringify(generalStore.state.employeesFilter));
        const tags = computed<IdName[]>(() => generalStore.state.tagsForEmployees);
        const copyTimeToAllActiveDaysToggle = ref(false);
        const firstActiveDay = computed(() => filter.value.schedules.find(x => x.active)?.dayOfWeek ?? -1);
        if (!tags.value.length) {
            const response = await api.getTagsAndProducts();
            if (response.data) {
                generalStore.commit('updateEmployeesTags', response.data.tags);
            }
        }

        function addClassIfNotExist(id: string) {
            const element = document.getElementById(id);
            if (element && !element.classList.contains('invalid')) {
                element.classList.add('invalid');
            }
        }

        function removeClassIfNotExist(id: string) {
            const element = document.getElementById(id);
            if (element && element.classList.contains('invalid')) {
                element.classList.remove('invalid');
            }
        }

        function removeInvalidClass() {
            for (const item in weekDayNames) {
                if (!isNaN(Number(item))) {
                    removeClassIfNotExist(`dayOfWeekStartTime-${item}`);
                    removeClassIfNotExist(`dayOfWeekEndTime-${item}`);
                }
            }
        }

        function validateDayOfWeeks() {
            let isValid = true;
            for (let i = 0; i < filter.value.schedules.length; i++) {
                const schedule = filter.value.schedules[i];
                if (!schedule.active) {
                    continue;
                }
                if (schedule.startTime == '') {
                    addClassIfNotExist(`dayOfWeekStartTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }
                if (schedule.endTime == '') {
                    addClassIfNotExist(`dayOfWeekEndTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }

                const startTime = moment(schedule.startTime, 'hh:mm');
                const endTime = moment(schedule.endTime, 'hh:mm');
                if (startTime.isSameOrAfter(endTime)) {
                    addClassIfNotExist(`dayOfWeekStartTime-${schedule.dayOfWeek}`);
                    addClassIfNotExist(`dayOfWeekEndTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }
            }
            return isValid;
        }

        const employeeSchedule: EmployeeSchedule[] = [];
        for (const item in weekDayNames) {
            if (!isNaN(Number(item))) {
                const scheduleIndex = filter.value.schedules.findIndex(x => x.dayOfWeek == Number(item));
                if (scheduleIndex >= 0) {
                    const existItem = filter.value.schedules[scheduleIndex];
                    existItem.active = true;
                    employeeSchedule.push(existItem);
                } else {
                    const newItem = new EmployeeSchedule();
                    newItem.dayOfWeek = Number(item);
                    employeeSchedule.push(newItem);
                }
            }
        }
        filter.value.schedules = employeeSchedule;

        async function Search() {
            removeInvalidClass();
            const isValid = validateDayOfWeeks();
            if (!isValid) {
                return;
            }
            swal.showLoading();
            filter.value.schedules = filter.value.schedules.filter(x => x.active === true)
            generalStore.commit('updateEmployeesFilter', filter.value);
            router.push({ name: 'EmployeesTable', params: { newSearch: '1' } });

            swal.close();
        }
        await Q.delay(400);
        swal.close();

        const copyTimeToAllActiveDays = (firstActiveDay: number) => {
            const firstActiveDaySchedule = filter.value.schedules.find(x => x.dayOfWeek === firstActiveDay);

            if (firstActiveDaySchedule && copyTimeToAllActiveDaysToggle.value) {

                for (const employeeSchedule of filter.value.schedules.filter(s => s.active)) {
                    employeeSchedule.startTime = firstActiveDaySchedule.startTime;
                    employeeSchedule.endTime = firstActiveDaySchedule.endTime;

                }
            }
        }

        function goToFilterTags() {
            filter.value.schedules = filter.value.schedules.filter(s => s.active)
            router.push({ name: 'modal-employees-filter-tags'})
        }

        function Close() {
            generalStore.commit('updateEmployeesFilter', filterBeforeSave);
            router.push({ name: 'EmployeesTable', params: { newSearch: '0'} });
        }
        return {
            goToFilterTags,
            openAddTagsModal,
            filter,
            tags,
            Close,
            Search,
            copyTimeToAllActiveDays,
            weekDayNames,
            copyTimeToAllActiveDaysToggle,
            firstActiveDay
        };
    }
});
